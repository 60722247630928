@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800&display=swap");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/5.0.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
	height: 100%;
	font-size: 13px;
	width: 100vw;
	overflow: hidden;
}


body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Catamaran", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* scroll-snap-type: y mandatory; */
	overflow-x: hidden;
	max-width: 100vw;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a,
a:hover,
a:visited,
a:active {
	color: inherit;
	text-decoration: none;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.poppins {
	font-family: "Poppins", sans-serif;
}
#root {
	height: 100%;
}

@media screen and (min-width: 768px) {
	html {
		font-size: 15px;
	}
}
@media (min-width: 1024px) {
	html {
		font-size: 16px;
	}
	body {
		overflow: hidden;
	}
	#main {
		overflow-y: scroll;
		height: 100vh;
		/* scroll-snap-type: y mandatory; */
	}
	.animate {
		/* scroll-snap-align: start; */
	}
}

/* width */
::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}
